<template>
  <v-dialog v-model="infoTypeEditDialog" persistent max-width="600">
    <v-card :loading="loading">
      <v-card-title>edit {{ name }}</v-card-title>
      <v-card-text>
        <v-text-field
            label="brand"
            dense
            v-model="view.brand"
            @input="$v.view.brand.$touch()"
        ></v-text-field>
        <v-text-field
            dense
            label="identity_key"
            v-model="view.identity_key"
            @input="$v.view.identity_key.$touch()"
            :counter="45"
        ></v-text-field>
        <v-text-field
            dense
            label="identity_type"
            v-model="view.identity_type"
            @input="$v.view.identity_type.$touch()"
        ></v-text-field>

        <v-switch
            v-model="view.active"
            :label="`Active: ${view.active.toString()}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  props: {
    infoTypeEditDialog: Boolean,
    myId: {type: Number, default: 0},
    name: {type: String, default: null},
  },
  // mixins: [Utils],
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      responseMessage: null,
      view: {
        brand: null,
        identity_key: null,
        identity_type: null,
        active: false
      },
    };
  },
  validations: {
    view: {
      brand: {
        required,
      },
      identity_key: {
        required,
      },
      identity_type: {
        required,
      },
      active: {
        required,
      }
    }
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.general.payments
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            let data = response.data;
            this.view.brand = data.brand;
            this.view.identity_key = data.identity_key;
            this.view.identity_type = data.identity_type;
            this.view.active = data.active;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = true;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeEdit", payload);
    },
    submit() {
      let payload = {id: this.myId, state: false, data: this.view};
      console.log('payload', payload)
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
