<template>
  <v-dialog v-model="infotypeNewDialog" persistent max-width="400">
    <v-card>
      <v-card-title>new payment type</v-card-title>
      <v-card-text>
        <v-text-field
            label="brand"
            dense
            v-model="view.brand"
            @input="$v.view.brand.$touch()"
        ></v-text-field>
        <v-text-field
            dense
            label="identity_key"
            v-model="view.identity_key"
            @input="$v.view.identity_key.$touch()"
            :counter="45"
        ></v-text-field>
        <v-text-field
            dense
            label="identity_type"
            v-model="view.identity_type"
            @input="$v.view.identity_type.$touch()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                small
                text
                color="blue"
                v-on="on"
                @click="submit"
                :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    infotypeNewDialog: Boolean
  },
  data() {
    return {
      loading: false,
      responseMessage: null,
      view: {
        brand: null,
        identity_key: null,
        identity_type: null,
      }
    };
  },
  validations: {
    view: {
      brand: {
        required,
      },
      identity_key: {
        required,
      },
      identity_type: {
        required,
      }
    }
  },
  created() {
  },
  methods: {
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeNew", payload);
    },
    submit() {
      let payload = {state: false, data: this.view};
      console.log('payload', payload)
      this.$emit("submitNew", payload);
    }
  }
};
</script>
